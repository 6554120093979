/**
 * Do not remove any keys!!
 * The shape of this file is to remain the same so that all options are know.
 *
 * If a key does not have a value, set the value to undefined.
 */
module.exports = {
  communityName: "Old Mill Village",
  gtagId: "G-VN4KGPSK7P",
  logo: {
    src: "https://cdn.maxxpm.com/Old Mill Village/om_logo.png",
    alt: "The Brickyard Logo",
  },
  nav: {
    bannerSubtitle: undefined,
    bannerTitle: undefined,
    bannerCTA: undefined,
  },
  footer: {
    address: "989 E Ella Ln",
    cityStateZip: "Heber, UT 84032",
    phone: "(385) 236-5514",
    copyrightYear: "2024",
    logo: "https://cdn.maxxpm.com/Old Mill Village/footer_logo.png",
  },
  listMarkerSrc: "https://cdn.maxxpm.com/Old Mill Village/li_marker.png",
  hasCalendlyPopup: false,
  calendlyLink: undefined,
  home: {
    banner: {
      image: "https://cdn.maxxpm.com/Old Mill Village/DSC_0925.jpg",
      video: undefined,
      title: "Upgraded Smart Townhomes",
      subtitle: "We Can't Wait To Welcome You Home!",
    },
    aboutUs: {
      paragraphs: [
        "Nestled in Heber, Utah, our 3-bed, 2.5-bath residences feature state-of-the-art smart home technology for modern convenience. With essential amenities such as Walmart, Heber Valley Hospital, and Wasatch High School less than 2 miles away, residents can enjoy accessibility without sacrificing the tranquility of the mountains.",
        // "Old Mill Village offers more than just a place to live; it's a community designed for comfort and leisure. Our clubhouse, playground, fitness center, and swimming pool provide residents with opportunities for relaxation and recreation right at their doorstep. Whether you're taking advantage of the nearby outdoor activities and scenic hikes or unwinding in the comfort of our smart townhomes and amenities, Old Mill Village offers the perfect balance of accessibility and serenity in Heber, Utah.",
        "Old Mill Village offers more than just a place to live; it's a community designed for comfort and leisure. Whether you're taking advantage of the nearby outdoor activities and scenic hikes or unwinding in the comfort of our smart townhomes and amenities, Old Mill Village offers the perfect balance of accessibility and serenity in Heber, Utah.",
      ],
      image: {
        src: "https://cdn.maxxpm.com/Old%20Mill%20Village/DJI_0305.jpg",
        alt: "Old Mill Village exterior photo",
      },
    },
    floorplanTitle: "Explore Our Floor Plan and Take a 3D Virtual Tour",
    floorplans: [
      {
        hasImage: true,
        image: {
          src: "https://cdn.maxxpm.com/Old Mill Village/Steve.jpg",
          alt: "Old Mill Village Floor Plan",
        },
        hasWalkthrough: true,
        walkthrough: {
          src: "https://my.matterport.com/show/?m=AzU8CKntVKp",
          title: "3d walkthrough",
        },
      },
    ],
    midContainerImage: "https://cdn.maxxpm.com/stockphotos/living room grey couch.jpg",
    imgRow: [
      {
        src: "https://cdn.maxxpm.com/Old%20Mill%20Village/DSC_2882-Enhanced-NR-Edit.jpg",
        alt: "Old Mill Village interior photo",
      },
      {
        src: "https://cdn.maxxpm.com/Old%20Mill%20Village/DJI_0294.jpg",
        alt: "Old Mill Village exterior photo",
      },
      {
        src: "https://cdn.maxxpm.com/Old Mill Village/DSC_2885-Enhanced-NR.jpg",
        alt: "Old Mill Village interior photo",
      },
    ],
    animalPolicy: {
      img: {
        src: "https://cdn.maxxpm.com/stockphotos/boy+kissing+dog.jpg",
        alt: "Animal policy stock photo",
      },
      policies: [
        "No more than 2 animals are allowed per property",
        "Cats/Dogs: $250/animal*",
        "$50/animal/month for animals under 50lbs*",
        "$75/animal/month for animals 50lbs+*",
        "One-time $75/animal DNA testing fee*",
        "Caged animals: $25/animal/month*",
        "Fish tanks are not permitted",
        "Rabbits and rodents of any kind are not permitted",
      ],
      breedRestrictions: [
        "Pit Bulls & Staffordshire Terriers",
        "Doberman Pinschers",
        "Rottweilers",
        "Chows",
        "Great Danes",
        "Presa Canarios/ Mastiffs",
        "Akitas",
        "Alaskan Malamutes",
        "Huskies",
        "German Shepherds",
        "Shar Peis",
        "Wolf-Hybrids",
      ],
    },
  },
  availability: {
    banner: {
      image: "https://cdn.maxxpm.com/Old Mill Village/DSC_0925.jpg",
      video: undefined,
      title: "Available Units",
      subtitle:
        "Unit availability is updated often from Monday-Friday during business hours. Evenings and weekends may not reflect accurate availability dates.",
    },
    hasWaitingList: false,
    waitingListJotformId: undefined,
    propertyGroup: "LEADS-OM",
  },
  faq: {
    banner: {
      image: "https://cdn.maxxpm.com/stockphotos/family smiling on bed.jpg",
    },
  },
};
